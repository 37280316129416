import React from "react";
import "./navbar.css";
import { useLocation } from "react-router-dom";
import { Link, Navigate, Routes, Route } from "react-router-dom";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import axios from "axios";

export default function SideNavigation(props) {
  const location = useLocation();
  const path = location.pathname;
  const [open, setOpen] = React.useState(true);
  const [open2, setOpen2] = React.useState(true);
  const [open3, setOpen3] = React.useState(true);
  const [open4, setOpen4] = React.useState(true);
  const [open5, setOpen5] = React.useState(true);
  const [open6, setOpen6] = React.useState(true);
  const [Loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState([]);
  const Logout = () => {
    // if (message.message.poistion = '') {
    //   localStorage.removeItem("userInfor");
    //   localStorage.removeItem("auth");
    //   window.location = "/employeeLogin";
    // } else {
    localStorage.removeItem("userInfor");
    localStorage.removeItem("auth");
    localStorage.removeItem("permission");
    localStorage.removeItem("riderDetails");
    window.location = "/login";
    // }
  };
  const Auth = localStorage.getItem("userInfor");
  const message = JSON.parse(Auth);
  const AdminId = message.message.id;
  const employeeAdminId = message.message.adminId;
  const employeePosition = message.message.position;

  // React.useEffect(() => {
  //   setLoading(false);
  //   if (AdminId) {
  //     axios
  //       .get(`https://api.s6.apancollections.com/permission/${AdminId}`)
  //       .then((res) => {
  //         setData(res.data);
  //         setLoading(true);
  //         const PermissionData = res.data.map((item) => {
  //           return item.permissionType;
  //         });
  //         localStorage.setItem("permission", PermissionData);
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //         setLoading(true);
  //       });
  //   }
  // }, []);

  React.useEffect(() => {
    setLoading(false);
    if (employeeAdminId) {
      axios
        .get(
          `https://api.s6.apancollections.com/employeePermission/${employeePosition}/${employeeAdminId}`
        )
        .then((res) => {
          setData(res.data);
          setLoading(true);
          const PermissionData = res.data.map((item) => {
            return item.permissionType;
          });
          localStorage.setItem("permission", PermissionData);
        })
        .catch((error) => {
          console.log(error);
          setLoading(true);
        });
    } else {
      if (AdminId) {
        axios
          .get(`https://api.s6.apancollections.com/permission/${AdminId}`)
          .then((res) => {
            // console.log(res.data);
            setData(res.data);
            setLoading(true);
            const PermissionData = res.data.map((item) => {
              return item.permissionType;
            });
            localStorage.setItem("permission", PermissionData);
          })
          .catch((error) => {
            console.log(error);
            setLoading(true);
          });
      }
    }
  }, []);

  const permissionData = data.map((item) => {
    return item.permissionType;
  });

  return (
    <>
      <ul className="nav-links">
        <Link
          to=""
          style={{ textDecoration: "none" }}
          onClick={() => setOpen4(!open4)}
        >
          <li
            className={`left-heading ${
              path === "/reportmanager" ||
              path === "/productivity" ||
              path === "/payment"
                ? "actived"
                : ""
            }`}
          >
            <span>Dashboard</span>
            <div className="icon-arrow-setup">
              {open4 ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
            </div>
          </li>
        </Link>
        {open4 && (
          <>
            {permissionData.includes("productivity") && (
              <Link to="/productivity" style={{ textDecoration: "none" }}>
                <li
                  className={`left-heading ${
                    path === "/productivity" ? "s-active" : ""
                  }`}
                >
                  <div>
                    <ArrowRightIcon />
                  </div>
                  <span style={{ paddingLeft: "20px" }}>Productivity</span>
                </li>
              </Link>
            )}
            {permissionData.includes("payment") && (
              <Link to="/payment" style={{ textDecoration: "none" }}>
                <li
                  className={`left-heading ${
                    path === "/payment" ? "s-active" : ""
                  }`}
                >
                  <div>
                    <ArrowRightIcon />
                  </div>
                  <span style={{ paddingLeft: "20px" }}>Payment</span>
                </li>
              </Link>
            )}
            {permissionData.includes("reportManager") && (
              <Link to="/reportmanager" style={{ textDecoration: "none" }}>
                <li
                  className={`left-heading ${
                    path === "/reportmanager" ? "s-active" : ""
                  }`}
                >
                  <div>
                    <ArrowRightIcon />
                  </div>

                  <span style={{ paddingLeft: "20px" }}>Report Manager</span>
                </li>
              </Link>
            )}
          </>
        )}
        {/* {permissionData.includes("importManager") && ( */}
        <Link
          to=""
          style={{ textDecoration: "none" }}
          onClick={() => setOpen(!open)}
        >
          <li
            className={`left-heading ${
              path === "/import-data-table" ||
              path === "/import-data" ||
              path === "/assigned-data" ||
              path === "/payment-list-table" ||
              path === "/assign-data" ||
              path === "/payment-list"
                ? "actived"
                : ""
            }`}
          >
            <span>Import Manager</span>
            <div className="icon-arrow-setup">
              {open ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
            </div>
          </li>
        </Link>
        {/* )} */}
        {open && permissionData.includes("importManager") && (
          <Link to="/import-data-table" style={{ textDecoration: "none" }}>
            <li
              className={`left-heading ${
                path === "/import-data-table" || path === "/import-data"
                  ? "s-active"
                  : ""
              }`}
            >
              <div>
                <ArrowRightIcon />
              </div>
              <span style={{ paddingLeft: "20px" }}>Import Data</span>
            </li>
          </Link>
        )}
        {open && permissionData.includes("assignManager") && (
          <Link to="/assigned-data" style={{ textDecoration: "none" }}>
            <li
              className={`left-heading ${
                path === "/assigned-data" || path === "/assign-data"
                  ? "s-active"
                  : ""
              }`}
            >
              {" "}
              <div>
                <ArrowRightIcon />
              </div>
              <span style={{ paddingLeft: "20px" }}>Assign Data</span>
            </li>
          </Link>
        )}
        {permissionData.includes("payment") && (
          <Link to="/payment-list-table" style={{ textDecoration: "none" }}>
            <li
              className={`left-heading ${
                path === "/payment-list-table" || path === "/payment-list"
                  ? "s-active"
                  : ""
              }`}
            >
              {" "}
              <div>
                <ArrowRightIcon />
              </div>
              <span style={{ paddingLeft: "20px" }}>Payment List</span>
            </li>
          </Link>
        )}
        {permissionData.includes("exportManager") && (
          <Link to="/export-data" style={{ textDecoration: "none" }}>
            <li
              className={`left-heading ${
                path === "/export-data" ? "actived" : ""
              }`}
            >
              <span>Export Manager</span>
            </li>
          </Link>
        )}
        {/* jon sheet */}
        {permissionData.includes("jobsheet") && (
          <Link
            to=""
            style={{ textDecoration: "none" }}
            onClick={() => setOpen5(!open5)}
          >
            <li
              className={`left-heading ${
                path === "/jobSheet" ||
                path === "/jobHighrise" ||
                path === "/jobCommercial" ||
                path === "/jobGenerator" ||
                path === "/highriseGenerator" ||
                path === "/commercialGenerator" ||
                path === "/jobSheetGenerator"
                  ? "actived"
                  : ""
              }`}
            >
              <span>Job Sheet</span>
              <div className="icon-arrow-setup">
                {open5 ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
              </div>
            </li>
          </Link>
        )}
        {open5 && permissionData.includes("jobsheet") && (
          <>
            <Link
              to=""
              style={{ textDecoration: "none" }}
              onClick={() => setOpen6(!open6)}
            >
              <li
                className={`left-heading ${
                  path === "/jobSheet" ||
                  path === "/jobHighrise" ||
                  path === "/jobCommercial" ||
                  path === "/jobGenerator" ||
                  path === "/highriseGenerator" ||
                  path === "/commercialGenerator"
                    ? "s-active"
                    : ""
                }`}
              >
                <div>
                  <ArrowRightIcon />
                </div>
                <span style={{ paddingLeft: "20px" }}>Categories</span>
                <div className="icon-arrow-setup">
                  {open6 ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
                </div>
              </li>
            </Link>
            {open6 && (
              <>
                <Link to="/jobSheet" style={{ textDecoration: "none" }}>
                  <li
                    className={`left-heading ${
                      path === "/jobSheet" || path === "/jobGenerator"
                        ? "s-active"
                        : ""
                    }`}
                  >
                    <div style={{ marginLeft: "18px" }}>
                      <ArrowRightIcon />
                    </div>
                    <span style={{ paddingLeft: "20px" }}>Landed</span>
                  </li>
                </Link>

                <Link to="/jobHighrise" style={{ textDecoration: "none" }}>
                  <li
                    className={`left-heading ${
                      path === "/jobHighrise" || path === "/highriseGenerator"
                        ? "s-active"
                        : ""
                    }`}
                  >
                    {" "}
                    <div style={{ marginLeft: "18px" }}>
                      <ArrowRightIcon />
                    </div>
                    <span style={{ paddingLeft: "20px" }}>Highrise</span>
                  </li>
                </Link>
                <Link to="/jobCommercial" style={{ textDecoration: "none" }}>
                  <li
                    className={`left-heading ${
                      path === "/jobCommercial" ||
                      path === "/commercialGenerator"
                        ? "s-active"
                        : ""
                    }`}
                  >
                    {" "}
                    <div style={{ marginLeft: "18px" }}>
                      <ArrowRightIcon />
                    </div>
                    <span style={{ paddingLeft: "20px" }}>Commercial</span>
                  </li>
                </Link>
              </>
            )}
            <Link to="/jobSheetGenerator" style={{ textDecoration: "none" }}>
              <li
                className={`left-heading ${
                  path === "/jobSheetGenerator" ? "s-active" : ""
                }`}
              >
                <div>
                  <ArrowRightIcon />
                </div>
                <span style={{ paddingLeft: "20px" }}>Job Sheet Generator</span>
              </li>
            </Link>
          </>
        )}
        {permissionData.includes("dataManager") && (
          <Link to="/call-manager" style={{ textDecoration: "none" }}>
            <li
              className={`left-heading ${
                path === "/call-manager" ? "actived" : ""
              }`}
            >
              <span>Data Manager</span>
            </li>
          </Link>
        )}
        {permissionData.includes("visualManager") && (
          <Link to="/images" style={{ textDecoration: "none" }}>
            <li
              className={`left-heading ${path === "/images" ? "actived" : ""}`}
            >
              <span>Visual Manager</span>
            </li>
          </Link>
        )}
        {permissionData.includes("riderManager") && (
          <Link to="/eaglesView" style={{ textDecoration: "none" }}>
            <li
              className={`left-heading ${
                path === "/eaglesView" ? "actived" : ""
              }`}
            >
              <span>Inview</span>
            </li>
          </Link>
        )}
        {permissionData.includes("humanResources") && (
          <Link
            to=""
            style={{ textDecoration: "none" }}
            onClick={() => setOpen2(!open2)}
          >
            <li
              className={`left-heading ${
                path === "/rider" || path === "/calendar" ? "actived" : ""
              }`}
            >
              <span>Human Rescource</span>
              <div className="icon-arrow-setup">
                {open2 ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
              </div>
            </li>
          </Link>
        )}
        {open2 && permissionData.includes("humanResources") && (
          <>
            <Link to="/rider" style={{ textDecoration: "none" }}>
              <li
                className={`left-heading ${
                  path === "/rider" ? "s-active" : ""
                }`}
              >
                {" "}
                <div>
                  <ArrowRightIcon />
                </div>
                <span style={{ paddingLeft: "20px" }}> Employee Details</span>
              </li>
            </Link>

            <Link to="/calendar" style={{ textDecoration: "none" }}>
              <li
                className={`left-heading ${
                  path === "/calendar" ? "s-active" : ""
                }`}
              >
                {" "}
                <div>
                  <ArrowRightIcon />
                </div>
                <span style={{ paddingLeft: "20px" }}>Employee Attendance</span>
              </li>
            </Link>

            <Link to="/roles" style={{ textDecoration: "none" }}>
              <li
                className={`left-heading ${
                  path === "/roles" ? "s-active" : ""
                }`}
              >
                {" "}
                <div>
                  <ArrowRightIcon />
                </div>
                <span style={{ paddingLeft: "20px" }}>Roles</span>
              </li>
            </Link>
          </>
        )}
        {permissionData.includes("setup") && (
          <Link
            to=""
            style={{ textDecoration: "none" }}
            onClick={() => setOpen3(!open3)}
          >
            <li
              className={`left-heading ${
                path === "/setup/company" || path === "/setup/policy"
                  ? "actived"
                  : ""
              }`}
            >
              <span>Setup</span>
              <div className="icon-arrow-setup">
                {open3 ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
              </div>
            </li>
          </Link>
        )}
        {open3 && permissionData.includes("setup") && (
          <>
            <Link to="/setup/company" style={{ textDecoration: "none" }}>
              <li
                className={`left-heading ${
                  path === "/setup/company" ? "s-active" : ""
                }`}
              >
                {" "}
                <div>
                  <ArrowRightIcon />
                </div>
                <span style={{ paddingLeft: "20px" }}> Company</span>
              </li>
            </Link>

            <Link to="/setup/policy" style={{ textDecoration: "none" }}>
              <li
                className={`left-heading ${
                  path === "/setup/policy" ? "s-active" : ""
                }`}
              >
                {" "}
                <div>
                  <ArrowRightIcon />
                </div>
                <span style={{ paddingLeft: "20px" }}>Policy</span>
              </li>
            </Link>
          </>
        )}
        <Link to="/changepassword" style={{ textDecoration: "none" }}>
          <li
            className={`left-heading ${
              path === "/changepassword" ? "actived" : ""
            }`}
          >
            <span>Change Password</span>
          </li>
        </Link>
        <div onClick={Logout}>
          <li className={`left-heading`} style={{ cursor: "pointer" }}>
            <span>Logout</span>
          </li>
        </div>
      </ul>
    </>
  );
}
